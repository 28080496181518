@import "./fonts.css";

:root {
  --light-bg: #e9edef;
  --primary-color: #005783;
  --title-color: #023047;
  --page-bg-color: #fafafa;
  --section-radius: 20px;
  --nav-link-font-size: 18px;
  --text-grey: #818181;
  --container-padding-lg: 50px 0;
  --container-padding-sm: 25px 0;
}

body {
  font-family: "Futura", sans-serif;
  background-color: var(--page-bg-color);
  font-weight: 500;
  min-height: 100vh;
}

p {
  font-family: "ProximaNova";
  color: var(--text-grey);
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 18px;
}


/* Container */
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

/* Buttons */
.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.75rem;
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #005783;
  --bs-btn-border-color: #005783;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0c4b6b;
  --bs-btn-hover-border-color: ##0c4b6b;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0c4b6b;
  --bs-btn-active-border-color: #0c4b6b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #005783;
  --bs-btn-disabled-border-color: #005783;
}
.btn-outline {
  --bs-btn-color: #005783;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #005783;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0c4b6b;
  --bs-btn-hover-border-color: ##0c4b6b;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0c4b6b;
  --bs-btn-active-border-color: #0c4b6b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #005783;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #005783;
}

.primary-color {
  color: var(--primary-color);
}
.bg-primary {
  background-color: var(--primary-color) !important;
}
.text-grey {
  color: var(--text-grey);
}
.light-bg {
  background-color: var(--light-bg);
}

/* Font Sizes */
h1,
h2,
h3,
h4,
h5 {
  margin: 1rem 0 1.38rem;
  font-family: "Futura", sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 2.488rem;
  font-weight: 600;
}

h2 {
  font-size: 2.074rem;
}

h3 {
  font-size: 1.728rem;
}

h4 {
  font-size: 1.44rem;
}

h5 {
  font-size: 1.2rem;
}

small,
.text_small {
  font-size: 0.833rem;
}
@media screen and (max-width:767px){
  .section-padding {
    padding: var(--container-padding-sm);
  }
  .sm-hide{
    display: none;
  }
}
@media screen and (max-width:768px){
  
  h1{font-size: 1.8rem;}
  h2{font-size: 1.65rem;}
  h2 {
    font-size: 1.65rem;
  }

  .carousel .thumbs {
    padding: 0px;
  }

  .custom-sm-device-query {
    align-items: center !important;
    flex-direction: column;
    text-align: center;
  }
  .cstm-btn-w-sm-dvices {
    width: 100%;
  }
}
/* font weights */
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}

/* Title */
.sub-title {
  font-family: "ProximaNova";
  font-size: 22px;
  font-weight: 500;
  line-height: 1.25em;
  color: var(--text-grey);
  margin: 20px 0;
}

/* Banner */
.bannerRow > * {
  border-right: 1px dashed #ced4da;
}
.bannerRow > :last-child {
  border-right: none;
}

.bannerRow .form-select {
  border: none;
}
.container-fluid {
  padding: 0 50px;
}
/* Form */
.form-control:focus,
.form-select:focus {
  box-shadow: none;
  border: none;
}
.form-control,
.form-select {
  border: 1px solid #c0ced5;
  border-radius: 4px;
  min-height: 45px;
  background-color: #fff;
}
.form-label {
  color: var(--primary-color);
  font-size: 16px;
}
/* Header */

header .navbar {
  height: 100%;
}
header .navbar-expand-lg .navbar-nav .nav-link {
  color: var(--primary-color);
  padding: 10px 20px;
  font-size: 18px;
}
header .navbar-nav {
  z-index: 9;
  position: relative;
  background-color: var(--page-bg-color);
}

@media (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 90px;
    width: 100%;
    box-shadow: rgb(23 60 80 / 15%) 0px 20px 24px -14px;
    z-index: 3;
  }
}
/* tabs */
.switch-tabs {
  border-radius: 45px;
  background-color: #d3dade;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: auto;
}

.switch-tabs .nav-content .nav-pills {
  border-radius: 45px;
  color: var(--primary-color);
}
.switch-tabs.nav-pills .nav-link {
  background-color: #d3dade;
  border-radius: 45px;
  font-size: 18px;
  color: var(--primary-color);
  justify-content: center;
  padding: 5px 25px;
}
.switch-tabs.nav-pills .nav-link.active,
.switch-tabs.nav-pills .show > .nav-link {
  background-color: #fff;
  color: var(--primary-color);
  box-shadow: 1px 3px 14px #8da5bcad;
  margin: 5px;
  height: 40px;
  align-items: center;
  display: flex;
}
/* Toggle switch */
.form-switch label {
  padding-left: 10px;
  line-height: 2.5em;
}
.form-switch .form-check-input {
  width: 4em;
  height: 2em;
}

.toggle-radio {
  border: 1px solid;
  border-radius: 40px !important;
  margin: 5px;
  padding: 2.5px 1.5rem;
  color: var(--primary-color);
  background-color: #fff;
  border-color: var(--primary-color);
}

.w-max-content {
  width: max-content;
}
/* Carousel */
.carousel .thumbs {
  padding: 0px;
}
.property-carousel > .carousel.carousel-slider {
  border-radius: 0.375rem 0.375rem 0px 0px;
  background-color: rgb(210, 210, 210);
}
.carousel-update > .carousel.carousel-slider {
  /* border-radius: 0.375rem 0.375rem 0px 0px; */
  background-color: rgb(210, 210, 210);
}

.property-carousel > .carousel.carousel-slider img {
  object-fit: cover;
}

.carousel-update > .carousel.carousel-slider img {
  object-fit: cover;
}
.carousel .thumb img {
  height: 60px;
  object-fit: cover;
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.form-check-input:focus {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(0 87 131 / 25%);
}
.carousel .control-dots {
  display: none;
}
.carousel .control-dots .dot {
  margin: 0 3px;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 49%);
}
/* Image carousel*/
.carousel {
  border-radius: 10px;
}

.img-slider .custom-legend {
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 15px;
  left: 14px;
  width: auto;
  border-radius: 20px;
  background: #fff;
  color: var(--primary-color);
  padding: 2px 10px;
  font-size: 14px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
  z-index: 100;
}
.carousel.carousel-slider .custom-legend {
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 15px;
  right: 14px;
  width: auto;
  border-radius: 20px;
  background: #58b754;
  color: #fff;
  padding: 2px 10px;
  font-size: 14px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
  z-index: 100;
}
.carousel-update .carousel .slide img {
  height: 420px;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid var(--primary-color);
}
/* close btn modal */
.btn-close {
  top: -7px;
  background-color: white;
  opacity: 1;
  color: var(--primary-color);
  border-radius: 50px;
  right: 20px;
  position: absolute;
}
.btn-close:hover {
  top: -7px;
  background-color: white;
  opacity: 1;
  color: var(--primary-color);
  border-radius: 50px;
  right: 20px;
  position: absolute;
}
@media (max-width: 528px) {
  .btn-close {
    top: 3px;
  }
  .btn-close:hover {
    top: 3px;
  }
}
/* calendar */
.pd-calendar {
  --rdp-cell-size: 45px;
  --rdp-background-color: #e7edff;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  margin: 1em;
}
.pd .nav {
  color: var(--text-grey) !important;
}
.pd > a:active {
  text-decoration: underline !important;
  color: var(--primary-color) !important;
}

.rdp-dropdown {
  color: #000 !important;
  background-color: #cccccc !important;
}
.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover {
  color: white !important;
  opacity: 1;
  background-color: #005783;
}

.rdp-day_range_middle {
  border-radius: 0;
  opacity: 0.4 !important;
}

.rdp-caption_label {
  color: var(--primary-color);
  font-size: 115%;
  font-weight: 500;
}

.rdp-weeknumber,
.rdp-day {
  color: var(--text-grey);
}
.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
}
.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: #fff;
}
.rdp-head_cell {
  color: var(--primary-color);
}
.rdp-button:not([disabled]) {
  color: var(--primary-color);
}

/* table */
.table > tbody > tr:last-child {
  border-bottom-style: hidden !important;
}

/* anchor links */
a:hover {
  color: var(--primary-color);
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 3px;
  overflow: hidden;
  color: var(--text-grey);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor {
  display: flex;
  background-color: #fff;
  font-size: 15px;
  padding-top: 5px;
}
.ant-anchor-ink-ball.visible {
  display: none;
}
.ant-anchor-wrapper{
  background-color: #fff;
}
.space-top{
  border-top: 1px solid #ccc;
  margin-top: 40px;
  padding-top: 40px;
}
.ant-anchor-ink:before {
  position: relative;
  display: none;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: " ";
}
/* Range slider */
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: var(--primary-color);
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track:hover {
  position: absolute;
  height: 4px;
  background-color: var(--primary-color);
  border-radius: 2px;
  transition: background-color 0.3s;
}

.ant-slider:hover .ant-slider-track {
  background-color: var(--primary-color);
}
.ant-slider:hover .ant-slider-rail {
  background-color: #92bbd0;
}

.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #92bbd0;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-rail:hover {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #92bbd0;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.gm-style .gm-style-iw-d {
  box-sizing: border-box;
  overflow: hidden !important;
  margin-bottom: 10px;
}

.property-carousel .carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  transition: height 0.15s ease-in;
  height: 210px;
}

.property-carousel .slide .custom-legend {
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 15px;
  right: 12px;
  margin-left: -45%;
  width: auto;
  border-radius: 20px;
  background: #58b754;
  color: #fff;
  padding: 4px 10px;
  font-size: 12px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.35s ease-in-out;
}
@media (max-width: 575.98px) {
  .container-fluid {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .section-padding {
    padding: var(--container-padding-sm);
  }
  .switch-tabs {
    border-radius: 20px;
    width: 100%;
    margin: auto;
  }
  .toggle-radio {
    border: 1px solid;
    border-radius: 40px !important;
    margin: 5px;
    padding: 1.5px 1.5rem;
    color: var(--primary-color);
    background-color: #fff;
    border-color: var(--primary-color);
    max-width: 55px;
    justify-content: center;
    display: flex;
  }
  .bannerRow > * {
    border-right: 0px dashed #ced4da;
  }
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .section-padding {
    padding: var(--container-padding-lg);
  }
  .gm-style-iw-c {
    max-width: 300px !important; 
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .gm-style-iw-c {
    /* min-width: 200px !important; */
    max-width: 300px !important;
  }

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mapDetails {
  position: relative;
  text-align: center;
  color: white;
}
/* sorting */
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: auto;
  height: 32px;
  padding: 0 11px;
}
.property-sort::before {
  content: "Sort by";
}
.sorting .ant-select-single.swhite .ant-select-selector {
  height: 30px !important;
  padding-top: 0;
  padding-bottom: 0;
}
.ant-select-single.swhite .ant-select-selector,
.ant-select-multiple.swhite .ant-select-selector {
  background-color: #fff;
  border: none;
  padding: 6px 11px;
}
.filter_by_sort .ant-select-selection-placeholder {
  padding-left: 85px;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}

.filter_by_sort .ant-select-selection-item::before {
  content: "Sort by: ";
  color: var(--text-grey);
  padding-right: 5px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  border-color: var(--primary-color);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  border: 1px solid #c0ced5;
  border-radius: 4px;
  min-height: 45px;
  background-color: #fff;
  width: auto;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}

.ant-select:hover {
  border-color: var(--primary-color);
}
.filter_ant_select .ant-select-selection-placeholder {
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
}
.filter_ant_select .ant-select-selection-placeholder::before {
  font-weight: 700;
  position: absolute;
  width: 100%;
  left: 5px;
}
.filter_ant_select .ant-select-selection-item {
  line-height: 25px !important;
  font-size: large;
}
.filter_ant_select .ant-select-selection-item .pre_digit {
  display: none;
}
.filter_ant_select .ant-select-arrow {
  transform: rotate(0) !important;
}

/* images */
.p-2 {
  cursor: pointer;
}
.shadow-card {
  box-shadow: 15px 13px 27px #173c501a;
  background-color: #fff;
  padding: 20px;
}

.rbt-menu.dropdown-menu {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 315px;
  top: calc(100% + 7px) !important;
  left: -30px !important;
  box-shadow: 15px 13px 16px #173c5026;
  border: 1px solid #e9f2f7;
  border-radius: 9px;
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  display: block;
  --bs-dropdown-link-active-bg: var(--primary-color);
}

.rbt-menu.dropdown-menu::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 20px;
  border-bottom: solid 20px rgb(255 255 255);
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
  top: -14px !important;
  /* left: 20px !important; */
}

.custom-marker {
  background-color: red;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

.scroll-wrap {
  position: relative;
}

.scroll-icon {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 10px #172f5034;
  opacity: 1;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 900;
}

.property-details-carousel > .carousel img{
  height: 100%;
}

.support-link {
  font-size: 14px;
  color: var(--primary-color);
}
@media screen and (max-width:1024px){
  .support-link {
    font-size: 14px;
    color: #FFF;
  }
}
.btn-group>:not(.btn-check:first-child)+.btn {
  margin-left: 5px !important;
}
.ant-anchor-ink-ball-visible.ant-anchor-ink-ball {
  display: none;
}


.gm-style-iw-c {
  min-width: 300px !important; 
}
.blog-content-link {
  color: var(--primary-color);
  text-decoration: underline;
}

.blog-content-link:hover {
  text-decoration: underline;
}
.gm-style-iw {
  padding-right: 15px !important;
}
.gm-style .gm-style-iw-c {
  padding-right: 15px !important;
}